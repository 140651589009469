import { PERSONAL_INFORMATION_HISTORY } from 'constant/PersonalInformationTerm';
import { useSearchParams } from 'react-router-dom';
import TermsFrame from './TermsFrame';

const PERSONAL_INFORMATION_FIELDS: Record<string, string> = {
  email: '이메일 주소',
  region: '지역',
  gender: '성별',
  dob: '생년월일',
  phone: '휴대전화번호',
  device: '휴대폰 기기 정보',
  previousPhone: '변경 전 휴대전화번호',
};

const convertKrFieldsFromSearchParams = (
  fieldsParam: string | null,
): string[] => {
  if (!fieldsParam) {
    return Object.values(PERSONAL_INFORMATION_FIELDS);
  }

  return fieldsParam
    .split(',')
    .map((field) => PERSONAL_INFORMATION_FIELDS[field])
    .filter(Boolean);
};

const PersonalInformationTerms = () => {
  const [searchParams] = useSearchParams();
  const personalInformationFields = convertKrFieldsFromSearchParams(
    searchParams.get('fields'),
  );

  return (
    <TermsFrame
      title="개인정보 수집 및 이용에 관한 동의(필수)"
      url="personal-information-terms"
      historyData={PERSONAL_INFORMATION_HISTORY(
        personalInformationFields.join(', '),
      )}
    />
  );
};

export default PersonalInformationTerms;
